<script>
	import Logo from '$lib/components/Logo.svelte';
</script>

<svelte:head>
	<title>Get MOSES Music - Producer/DJ | Hip-Hop, Soul, and Experimental Electronic Club/Dance Music</title>
	<meta name="description" content="Experience the dynamic beats of Moses, MHHA DJ of the Year [2014] and owner of Higher Education Records. DJ for Lupe Fiasco, Kaskade, Big Sean and more. Blending hip-hop, soul, and experimental electronic club/dance music in the Milwaukee/Chicago area.">
  <meta name="keywords" content="Moses, Get Moses Music, Producer, DJ, Hip-Hop, Soul, Experimental Electronic, Club Music, Dance Music, Higher Education Records, MHHA DJ of the Year, Lupe Fiasco, Kaskade, Big Sean, Live Performance, Turntablism, MPC, Milwaukee, Chicago">
  <meta property="og:title" content="Get Moses Music - Producer/DJ | Hip-Hop, Soul, and Experimental Electronic Club/Dance Music">
  <meta property="og:description" content="Experience the dynamic beats of Moses, MHHA DJ of the Year [2014] and owner of Higher Education Records. DJ for Lupe Fiasco, Kaskade, Big Sean and more. Blending hip-hop, soul, and experimental electronic club/dance music in the Milwaukee/Chicago area.">
  <!-- <meta property="og:image" content="[Insert URL of an engaging image that represents Moses' brand and music]"> -->
  <meta name="twitter:title" content="Get Moses Music - Producer/DJ | Hip-Hop, Soul, and Experimental Electronic Club/Dance Music">
  <meta name="twitter:description" content="Experience the dynamic beats of Moses, MHHA DJ of the Year [2014] and owner of Higher Education Records. DJ for Lupe Fiasco, Kaskade, Big Sean and more. Blending hip-hop, soul, and experimental electronic club/dance music in the Milwaukee/Chicago area.">
  <!-- <meta name="twitter:image" content="[Insert URL of an engaging image that represents Moses' brand and music]"> -->
</svelte:head>

<div class="bg-[url('/moses-summerfest-2019.jpg')] m-0 bg-no-repeat bg-cover bg-center w-full h-screen text-center">
	<div class="pt-44">
		<Logo />
		<a
			href="https://getmosesmusic.bandcamp.com/"
			target="_blank"
			rel="noopener noreferrer"
			class="max-w-[400px] font-bold p-2 bg-gray-900 rounded hover:text-yellow-400 text-xl mt-16 block mx-auto"
		>
			NEW ALBUM: NIGHT SNACKS
		</a>
	</div>
</div>
